@media (max-width: 1440px) {
  .search-container {
    display: none;
  }
}

@media (max-width: 1017px) {
  .offcanvas-collapse {
    position: fixed;
    top: 56px; /* Height of navbar */
    bottom: 0;
    left: 100%;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    visibility: hidden;
    background-color: #f8f9fa;
    transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
  }
  .offcanvas-collapse.open {
    visibility: visible;
    transform: translateX(-100%);
  }
}

.bg-custom-light {
  background-color: #e7e7e7;
}

.bg-dim {
  background-color: rgba(0, 0, 0, 0.6);
}

.cover {
  object-fit: cover;
}

.opacity-6 {
  opacity: 0.6;
}

.opacity-5 {
  opacity: 0.5;
}

.image-vertical-scroller {
  overflow-y: auto;
  max-height: 460px;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.image-vertical-scroller::-webkit-scrollbar {
  display: none;
}

.h-scroller {
  position: relative;
  z-index: 2;
  height: 3.75rem;
  overflow-y: hidden;
}

.h-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.h-underline .h-link {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 0.875rem;
  color: #6c757d;
}

.logo {
  width: 135px;
  height: auto;
}

.carousel-caption {
  /* text-shadow: 3px 3px 20px #89CFF0, -2px 1px 30px #89CFF0; */
  /* text-shadow: 3px 3px 20px #ff99cc, -2px 1px 30px #ff99cc; */
  background-color: rgb(255 255 255 / 20%);
  border-radius: 5px;
  color: #000;
  font-size: 25px;
  width: 300px;
  margin: 0 auto;
}

.carousel-caption h5 {
  font-size: 30px;
  font-weight: 600;
}

.card-img-top {
  height: 300px;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.auto-text-decoration {
  text-decoration: auto !important;
}

.card-title {
  height: 60px;
}

.hover-shadow {
  transition: box-shadow 0.3s ease-in-out;
}

.hover-shadow:hover {
  box-shadow: rgb(0 0 0 / 7%) 0px 1px 2px, rgb(0 0 0 / 7%) 0px 2px 4px,
    rgb(0 0 0 / 7%) 0px 4px 8px, rgb(0 0 0 / 7%) 0px 8px 16px,
    rgb(0 0 0 / 7%) 0px 16px 32px, rgb(0 0 0 / 7%) 0px 32px 64px !important;
}

li.nav-item {
  margin: auto 2rem;
}

.submit-btn {
  background: #000;
  color: #fff;
  padding: 3px 7px;
}

.search-input {
  color: rgba(0, 0, 0, 0.4);
  padding: 3px;
}
